<template>
    <card>
      <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <b-tabs content-class="mt-0" fill>
                  <b-tab :title="$t('license_management.application')" active>
                    <div v-if="loading">
                      <Loading />
                    </div>
                    <b-overlay v-else>
                      <div class="p-3">
                        <b-alert show dismissible variant="danger" v-if="appDetail.application?.status == 7 && appDetail.application?.recommendation && appDetail.application?.recommendation.remarks">
                            <strong>{{ $t('teaGardenService.reject_remarks') }}</strong>: {{ appDetail.application?.recommendation.remarks }}
                        </b-alert>
                        <b-row v-if="appDetail.application?.status == 9 && appDetail.application?.return_remarks">
                            <b-col>
                                <div>
                                <b-alert show dismissible variant="danger">
                                    <strong>{{ $t('teaGardenBtriService.return_remarks_note') }}</strong>: {{ appDetail.application?.return_remarks }}
                                </b-alert>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="12">
                            <div class="text-right">
                              <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                              </b-button>
                            </div>
                            <div>
                              <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                                <div class="" style="width:50%"><b>{{ $t('license_management.agreement_no') }}</b> : {{
                                  appDetail.application.app_id }}</div>
                                <div class="text-right" style="width:50%"><b>{{ $t('externalLrcpn.expire_date') }}</b> : {{
                                  appDetail.application.expired_date | dateFormat }}</div>
                              </div>
                              <div class="stepper-wrapper">
                                <div :class="item.value <= appDetail.application.status ? `stepper-item completed` : `stepper-item`"
                                  v-for="(item, index) in stepList" :key="index">
                                  <div class="step-counter">
                                    <i v-if="item.value <= appDetail.application.status" class="ri-check-fill"></i>
                                    <span v-else>{{ $n(index+1) }}</span>
                                  </div>
                                  <div class="step-name">{{ item.text }}</div>
                                </div>
                              </div>
                              <!-- Application view start -->
                              <div class="application-form-wrapper application-form-view-wrapper mt-5">
                                <div class="application-itmes">
                                  <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.owner_name')" label-for="ApplicantNameEn">
                                            <p class="input-field"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.email')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.email }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.mobile')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.mobile | mobileNumber }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.nid')" label-for="DesignationEn">
                                            <p class="input-field">{{ $n(appDetail.nid, { useGrouping: false }) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.applicant_company')" label-for="DesignationEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.application_type')" label-for="DesignationEn">
                                            <p class="input-field">{{ appDetail.application.application_type === 2 ? currentLocale=== 'bn' ? 'নবায়ন' : 'Renew' : currentLocale=== 'bn' ? 'নতুন নিবন্ধন' : 'New Registration' }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6" v-if="appDetail.old_license_attachment">
                                          <b-form-group :label="$t('teaGardenPanel.old_license_attachment')" label-for="DesignationEn">
                                            <div v-if="appDetail.application.application_type === 2">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + (isImage(appDetail.old_license_attachment) ? 'storage/' : '') + appDetail.old_license_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.application.application_type === 2">
                                          <b-form-group :label="$t('teaGardenPanel.old_license_expire_date')" label-for="DesignationEn">
                                            <p class="input-field">{{ appDetail.old_license_expire_date | dateFormat }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                                       <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.business_address')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenConfig.area_type')" label-for="AreaType">
                                            <p class="input-field">{{ getAreaTypeName(appDetail.c_area_type_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <!-- City Corporation Area Type -->
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenConfig.division')" label-for="Division">
                                            <p class="input-field">{{ getDivisionName(appDetail.c_division_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenConfig.district')" label-for="District">
                                            <p class="input-field">{{ getDistrictName(appDetail.c_district_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.c_area_type_id === 1">
                                          <b-form-group :label="$t('globalTrans.city_corporation')" label-for="CityCorporation">
                                            <p class="input-field">{{ getCityCorName(appDetail.c_city_corporation_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.c_area_type_id === 2 || appDetail.c_area_type_id === 3">
                                          <b-form-group :label="$t('globalTrans.upazila')" label-for="Upazila">
                                            <p class="input-field">{{ getUpazillaName(appDetail.c_upazila_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.c_area_type_id === 3">
                                          <b-form-group :label="$t('globalTrans.union')" label-for="Union">
                                            <p class="input-field">{{ getUnionName(appDetail.c_union_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.c_area_type_id === 2">
                                          <b-form-group :label="$t('globalTrans.pouroshova')" label-for="puroshova">
                                            <p class="input-field">{{ getPouroName(appDetail.c_pauroshoba_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.post_code')" label-for="PostCode">
                                            <p class="input-field">{{ $n(appDetail.c_post_code, { useGrouping: false }) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.address')" label-for="AddressEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.c_address_en : appDetail.c_address_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                   <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.others')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.business_type')" label-for="AddressEn">
                                            <p class="input-field">{{ getBusinessTypeName(appDetail.business_type_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 2">
                                          <b-form-group :label="$t('teaGardenPanel.company_memorandum')" label-for="AddressEn">
                                            <div v-if="appDetail.company_memorandum">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + appDetail.company_memorandum"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 2">
                                          <b-form-group :label="$t('teaGardenPanel.certificate_of_incor')" label-for="AddressEn">
                                            <div v-if="appDetail.certificate_of_incor">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + appDetail.certificate_of_incor"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 3">
                                          <b-form-group :label="$t('teaGardenPanel.agreement_attachment')" label-for="AddressEn">
                                            <div v-if="appDetail.agreement_attachment">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + appDetail.agreement_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 3">
                                          <b-form-group :label="$t('teaGardenPanel.farm_reg_certificate')" label-for="AddressEn">
                                            <div v-if="appDetail.farm_reg_certificate">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + appDetail.farm_reg_certificate"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 3">
                                          <b-form-group :label="$t('teaGardenPanel.necessary_documents')" label-for="AddressEn">
                                            <div v-if="appDetail.necessary_documents">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="teaGardenServiceBaseUrl + appDetail.necessary_documents"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.business_type_id === 3">
                                          <b-form-group :label="$t('teaGardenPanel.participant_info')" label-for="AddressEn">
                                             <p class="input-field" v-html="currentLocale === 'en' ? appDetail.participant_info_en : appDetail.participant_info_bn"></p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.establishment_year')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.establishment_year, { useGrouping: false }) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.authorized_capital')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.authorized_capital, { useGrouping: false }) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.no_of_director')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.no_of_director) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.no_of_employee')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.no_of_employee) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.paid_capital')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.paid_capital) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('teaGardenPanel.tin_number')" label-for="AddressEn">
                                            <p class="input-field">{{ $n(appDetail.tin_number, { useGrouping: false }) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                              <b-row>
                                <b-col md="6">
                                  <div>
                                    <h6 class="mb-3 tag">{{ $t('dealer.important_document') }}</h6>
                                  </div>
                                  <div>
                                    <ul>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(1) }}. {{ $t('teaGardenPanel.trade_license') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.trade_license">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.trade_license) ? 'storage/' : '') + appDetail.trade_license"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                        <p v-if="appDetail.trade_license_fiscal_year_id" class="text-danger">{{$t('teaGardenPanel.trade_license_fiscal_year_id')}}: <strong>{{getFiscalYearName(appDetail.trade_license_fiscal_year_id)}}</strong></p>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(2) }}. {{ $t('teaGardenPanel.organization_structure_file') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.organization_structure_file">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(appDetail.organization_structure_file) ? 'storage/' : '') + appDetail.organization_structure_file"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(3) }}. {{ $t('teaGardenPanel.vat_certificate') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.vat_certificate">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.vat_certificate) ? 'storage/' : '') + appDetail.vat_certificate"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(4) }}. {{ $t('teaGardenPanel.bank_solvency') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.bank_solvency_certificate">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.bank_solvency_certificate) ? 'storage/' : '') + appDetail.bank_solvency_certificate"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(5) }}. {{ $t('teaGardenPanel.profile_photo') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.profile_photo">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.profile_photo) ? 'storage/' : '') + appDetail.profile_photo"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(6) }}. {{ $t('teaGardenPanel.director_biodata') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.director_biodata">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.director_biodata) ? 'storage/' : '') + appDetail.director_biodata"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(7) }}. {{ $t('teaGardenPanel.garden_owner_certificate') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.garden_owner_certificate">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                 :href="teaGardenServiceBaseUrl + (isImage(appDetail.garden_owner_certificate) ? 'storage/' : '') + appDetail.garden_owner_certificate"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(8) }}. {{ $t('teaGardenPanel.agreement_paper') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.agreement_paper">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.agreement_paper) ? 'storage/' : '') + appDetail.agreement_paper"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="d-flex justify-content-between">
                                          <span>{{ $n(9) }}. {{ $t('teaGardenPanel.chief_executives_signature') }}</span>
                                          <div class="list-btn">
                                            <div v-if="appDetail.signature">
                                              <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                              <a target="_blank"
                                                :href="teaGardenServiceBaseUrl + (isImage(appDetail.signature) ? 'storage/' : '') + appDetail.signature"><i
                                                  style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                            </div>
                                            <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col md="12" class="text-right">
                            <b-button variant="primary " class="btn-sm" @click="back()">{{ $t('globalTrans.back') }}</b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </b-overlay>
                  </b-tab>
                  <b-tab :title="$t('license_management.forward_history')" v-if="!isExternalUser && forwardsShow">
                    <div v-if="forwarLoad || loading" class="col-md-12">
                      <forward-loading></forward-loading>
                    </div>
                    <div v-else class="row">
                      <div class="col-md-10 offset-md-1">
                        <div v-for="(forward, index) in appDetail.application.forwards" :key="index">
                          <b-card>
                            <b-card-title>
                              <h5 style="font-size:17px">{{ getCardTitle(forward) }}</h5>
                            </b-card-title>
                            <b-card-sub-title style="font-size:14px">
                              <span class="badge badge-success">{{ $t('license_management.sender') }}</span> : {{
                              getUserName(forward.sender_id) }} <i class=" ml-3 ri-arrow-right-line"></i>
                              <span class="badge badge-primary ml-3">{{ $t('license_management.receiver') }}</span> : {{
                              getUserName(forward.receiver_id) }}
                            </b-card-sub-title>
                            <b-card-text class="mt-3">
                              <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? forward.remarks_bn : forward.remarks
                                }}</span>
                            </b-card-text>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab :title="$t('teaGardenPanel.recommendation_history')" v-if="!isExternalUser && recommendationShow">
                    <div v-if="forwarLoad || loading" class="col-md-12">
                      <recommendation-loading></recommendation-loading>
                    </div>
                    <div v-else class="row">
                      <div class="col-md-10 offset-md-1">
                        <div v-for="(recommendation, index) in appDetail.application.recommendation_forwards" :key="index">
                          <b-card>
                            <b-card-title>
                              <h5 style="font-size:17px">{{ getCardTitle(recommendation) }}</h5>
                            </b-card-title>
                            <b-card-sub-title style="font-size:14px">
                              <span class="badge badge-success">{{ $t('license_management.sender') }}</span> : {{
                              getRecUserName(recommendation.sender_id) }} <i class=" ml-3 ri-arrow-right-line"></i>
                              <span class="badge badge-primary ml-3">{{ $t('license_management.receiver') }}</span> : {{
                              getRecUserName(recommendation.receiver_id) }}
                            </b-card-sub-title>
                            <b-card-text class="mt-3">
                              <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? recommendation.remarks : recommendation.remarks
                                }}</span>
                            </b-card-text>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { authServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
import ForwardLoading from './loading/ForwardLoading.vue'
import Loading from './loading/Details.vue'
export default {
  name: 'Details',
  components: {
    Loading,
    ForwardLoading
  },
  data () {
    return {
      loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {},
      forwardsShow: false,
      recommendationShow: false,
      users: [],
      recUsers: []
    }
  },
  created () {
    this.appDetail = this.getAppDetail()
  },
  computed: {
    businessTypeList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'ব্যাক্তি মালিকানাধীন' : 'Individual' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'লিমিটেড কোম্পানি' : 'Limited Company' },
        { value: 3, text: this.$i18n.locale === 'bn' ? 'অংশীদারি কারবার' : 'Partnership Business' },
        { value: 4, text: this.$i18n.locale === 'bn' ? 'সরকারি / আধা সরকারি / স্বায়ত্তশাসিত প্রতিষ্ঠান' : 'Govt / Semi Govt / Autonomous Institutions' }
      ]
    },
    recommendStatusList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Reject' : 'বাতিল', text_en: 'Reject', text_bn: 'বাতিল' }
      ]
      return list
    },
    appId () {
      return this.$route.params.id
    },
    currentLocale () {
      return this.$i18n.locale
    },
    stepList () {
      const setp = [
        { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Recommendation' : 'সুপারিশ', text_en: 'Recommendation', text_bn: 'সুপারিশ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' },
        { value: 9, text: this.$i18n.locale === 'en' ? 'Returned' : 'ফেরৎ প্রাপ্ত', text_en: 'Returned', text_bn: 'ফেরৎ প্রাপ্ত' }
      ]
      if (this.appDetail.application.status === 7) {
        return setp.filter(item => item.value !== 6 && item.value !== 9)
      } else if (this.appDetail.application.status === 9) {
        return setp.filter(item => [2, 9].includes(item.value))
      } else {
        return setp.filter(item => item.value !== 7 && item.value !== 9)
      }
    },
    isExternalUser () {
      let isExternal = false
      if (this.$store.state.Auth.authUser.user_type === 2) {
        isExternal = true
      }
      return isExternal
    }
  },
  methods: {
    getStatusName (status) {
      return status === 2 ? this.$t('dealer.reject') + ' ' + this.$t('globalTrans.note') : this.$t('dealer.resubmit') + ' ' + this.$t('globalTrans.note')
    },
    getCardTitle (tcb) {
      const tcbStep = this.$store.state.LicenseRegistrationService.commonObj.tcbStepList.find(item => item.value === parseInt(tcb.step_id))
      if (typeof tcbStep !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tcbStep.text_bn : tcbStep.text_en
      } else {
        return ''
      }
    },
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/license-application/broker-show' + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
            if (this.appDetail.application.forwards.length) {
              this.forwardsShow = true
              this.getForwardedUsers(result.data.application.forwards)
            }
            if (this.appDetail.application.recommendation_forwards.length) {
              this.recommendationShow = true
              this.getRecommendedUsers(result.data.application.recommendation_forwards)
            }
        }
        this.loading = false
    },
    getAreaTypeName (id) {
      const obj = this.$store.state.commonObj.areaTypeList.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPouroName (id) {
      const data = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getDivisionName (id) {
      const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getCityCorName (id) {
      const data = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getBusinessTypeName (id) {
      const data = this.businessTypeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getUpazillaName (id) {
      const data = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getFiscalYearName (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'bn' ? data?.text_bn : data?.text_en
    },
    getUnionName (id) {
      const data = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
      return this.currentLocale === 'bn' ? data?.text_bn : data?.text_en
    },
    getDistrictName (id) {
      const data = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    getRecUserName (userId) {
      const user = this.recUsers.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    async getForwardedUsers (forwards) {
      this.forwarLoad = true
      var userIds = []
      forwards.map((item, key) => {
        userIds.push(item.sender_id)
        userIds.push(item.receiver_id)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.users = response
          } else {
            this.users = []
          }
          this.forwarLoad = false
      })
    },
    async getRecommendedUsers (recommends) {
      this.forwarLoad = true
      var userIds = []
      recommends.map((item, key) => {
        userIds.push(item.sender_id)
        userIds.push(item.receiver_id)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.recUsers = response
          } else {
            this.recUsers = []
          }
          this.forwarLoad = false
      })
    },
    async pdfExport () {
      this.loading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 30)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/license-application/broker-show/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      this.loading = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCardTitleRecommendation (status) {
      const tfaStepList = this.recommendStatusList.find(item => item.value === parseInt(status))
      if (typeof tfaStepList !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tfaStepList.text_bn : tfaStepList.text_en
      } else {
        return ''
      }
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    getSubDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.subDesignationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
